import { createGlobalStyle } from 'styled-components';
// @ts-expect-error ignore
import RedHatDisplay from './RedHatDisplay-Medium.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: RedHatDisplay;
    src: url(${RedHatDisplay}) format('truetype');
    font-display: swap;
  }
`;
