import React from 'react';
import { Footer } from 'components/footer';
import { UniversityLogo } from 'components/icon';
import sections from '../db/homepage';
import SectionRenderer from 'components/section-renderer';

const Homepage = (): JSX.Element => (
  <>
    <UniversityLogo />
    <SectionRenderer sections={sections} />
    <Footer />
  </>
);

export default Homepage;
