import React from "react";
import styled from "styled-components";
//@ts-expect-error
import UniversityIcon from '../assets/hsa-logo.svg';

const Wrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
`;

const Image = styled.img`
  width: 350px;
`;

export const UniversityLogo = (): JSX.Element => (
  <Wrapper id={"university-logo"}>
    <a href="https://www.tha.de/">
      <Image src={UniversityIcon} alt="Technical University Augsburg" />
    </a>
  </Wrapper>
);
