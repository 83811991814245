import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import { COLORS } from "styles";

type Props  = {
  isOpen: boolean,
  onClose: () => void,
  text: string,
  title: string,
};

export const CustomModal = ({ isOpen, onClose, text, title }: Props): JSX.Element => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent backgroundColor={COLORS.egg} maxWidth={"700px"}>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody whiteSpace={"pre-wrap"}>
        {text}
      </ModalBody>

      <ModalFooter>
        <Button backgroundColor={COLORS.red} color={COLORS.egg} onClick={onClose}>
          Schließen
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
