/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unassigned-import */
/* eslint-disable import/no-unresolved */
import { createRoot } from 'react-dom/client';
import * as React from 'react';
import './styles.css';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from 'styled-components';
import App from './app';
import defaultTheme from './styles/theme';
import GlobalFonts from './fonts/global-fonts';
// import './fonts/RedHatDisplay-Regular.ttf';

createRoot(document.getElementById('root')).render(
  <ChakraProvider>
    <ThemeProvider theme={defaultTheme}>
      <GlobalFonts />
      <App />
    </ThemeProvider>
  </ChakraProvider>,
);
