export const COLORS = {
  black: '##1e1e1e',
  white: '#FFF',
  egg: '#F6F4F0',
  red: '#f43662',
  orange: '#ff9800',
  blue: '#0C2236',
  dark_grey: '#999',
  grey: '#f2f2f2'
};
