import { Button } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import { BodyLarge, COLORS, Heading5, } from "styles";
import breakpoints from "styles/breakpoints";

type Props  = {
  icon: string,
  title: string,
  description: string,
  onClickMore: (title: string) => void,
};

const Image = styled.img`
  border-radius: 25px;
  width: 240px;
  height: fit-content;
  background-color: unset;
  scale: 1;
  transition: scale 0.5s ease 0s;
  height: inherit;
  width: inherit;
`;

const ImageContainer = styled.div`
  display: flex;
  background-color: ${COLORS.red};
  width: 170px;
  height: 170px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;

  ${breakpoints.md`
    width: 270px;
    height: 270px;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  text-align: center;
  justify-content: center;
  align-items: center;

  > p {
    max-width: 400px;
  }

  &:hover ${Image} {
    scale: 1.1;
  }

  ${breakpoints.md`
    width: 270px;
    justify-content: start;
    align-items: start;
    text-align: unset;
  `}
`

export const TopicCard = ({ icon, title, description, onClickMore }: Props): JSX.Element => (
  <Container>
    <ImageContainer>
      <Image src={icon} alt={title} />
    </ImageContainer>
    <Heading5 color={COLORS.orange}>{title}</Heading5>
    <BodyLarge color={COLORS.black}>{description}</BodyLarge>
    <Button maxWidth={'fit-content'} backgroundColor={COLORS.red} color={COLORS.white} padding={'25px 25px'} borderRadius={'10px'} marginTop={'15px'} onClick={() => onClickMore(title)}>Mehr Erfahren</Button>
  </Container>
);
