import styled from 'styled-components';
import { COLORS } from './colors';
import breakpoints from './breakpoints';

type HeadingProps = {
  light?: boolean,
  color?: string,
  textAlignCenter?: boolean,
  width?: string,
  marginTop?: string,
};

type Subtitle1Props = {
  light?: boolean,
  color?: string,
  textAlignCenter?: boolean,
  width?: string,
};

const Heading1 = styled.h1<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 120%;
  color: #272443;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop  : '0px')};

  ${breakpoints.md`
    font-size: 80px;
  `}
`;

const Heading2 = styled.h2<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-size: 32px;
  white-space: pre-line;
  text-align: center;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};

  ${breakpoints.md`
    font-size: 50px;
  `}
`;

const Heading3 = styled.h3<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-size: 30px;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};

  ${breakpoints.md`
    font-size: 48px;
  `}
`;

const Heading4 = styled.h4<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-size: 30px;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};

  ${breakpoints.md`
    font-size: 34px;
  `}
`;

const Heading5 = styled.h5<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-size: 20px;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};

  ${breakpoints.md`
    font-size: 24px;
  `}
`;

const Heading6 = styled.h6<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};

  ${breakpoints.md`
    font-size: 20px;
  `}
`;

const Subtitle1 = styled.p<Subtitle1Props>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 175%;
  letter-spacing: 0.05em;
  text-transform: none;
  color: ${COLORS.dark_grey};
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};

  ${breakpoints.md`
    font-size: 24px;
  `}
`;

const Subtitle2 = styled(Subtitle1)`
  font-size: 10px;

  ${breakpoints.md`
    font-size: 14px;
  `}
`;

const Subtitle3 = styled(Subtitle1)`
  font-size: 10px;
  text-transform: uppercase;

  ${breakpoints.md`
    font-size: 14px;
  `}
`;

const ButtonLarge = styled.p`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  ${breakpoints.md`
    font-size: 16px;
  `}
`;

const BodyLarge = styled.p<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  white-space: pre-line;
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};
`;

type LinkProps = {
  style?: { [key: string]: string },
};

const Link = styled.p<LinkProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: '16px';
  line-height: 150%;
  width: auto;
  color: ${(props) => (props.color ? props.color : COLORS.white)};
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
  ${(props) => props.style && props.style}
`;

const BodyMedium = styled(BodyLarge)`
  display: inline-block;
  font-size: 16px;
`;

const BodyMediumLink = styled.a<HeadingProps>`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => (props.color ? props.color : COLORS.black)};
  white-space: pre-line;
  text-align: ${(props) => (props.textAlignCenter ? 'center'  : 'unset')};
  width: ${(props) => (props.width ? props.width  : 'auto')};

  :hover {
    color: ${COLORS.red}
  }
`

const BodySmall = styled(BodyLarge)`
  font-size: 16px;
`;

const ButtonMedium = styled(ButtonLarge)`
  font-size: 14px;
`;

const ButtonSmall = styled(ButtonLarge)`
  font-size: 12px;
`;

const CaptionText = styled.caption`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  letter-spacing: 0.05em;
`;

const Overline = styled.p`
  font-family: 'TH-Light', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Subtitle1,
  Subtitle2,
  Subtitle3,
  BodyLarge,
  BodyMedium,
  BodyMediumLink,
  BodySmall,
  ButtonLarge,
  ButtonMedium,
  ButtonSmall,
  CaptionText,
  Overline,
  Link,
};
