import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  color: {
    black: {
      black_1: '#021410',
      black_50: '#F2F3F3',
      black_300: '#B3B8B7',
      black_600: '#677270',
      black_900: '#1B2B27',
    },
    white: {
      white_1: '#F2EDE6',
      white_2: '#FFF',
      white_50: '#F2F3F3',
      white_300: '#B3B8B7',
      white_600: '#677270',
      white_900: '#1B2B27',
    },
    primary: {
      primary_1500: '#99331C',
      primary_1250: '#CC553A',
      primary_1000: '#FF6A48',
      primary_750: '#FF8F76',
      primary_500: '#FFB5A4',
      primary_250: '#FFDAD1',
      primary_50: '#FFF7F6',
    },
    secondary: {
      secondary_1000: '#064635',
      secondary_750: '#447468',
      secondary_500: '#83A39A',
      secondary_250: '#C1D1CC',
      secondary_50: '#F2F6F5',
    },
    notification: {
      error: {
        error_text: '#FF6A48',
        error_bg: '#FF8F76',
        error_icon: '#FF8F76',
      },
      info: {
        info_text: '#FF6A48',
        info_bg: '#FF8F76',
        info_icon: '#FF8F76',
      },
      warning: {
        warning_text: '#FF6A48',
        warning_bg: '#FF8F76',
        warning_icon: '#FF8F76',
      },
      success: {
        success_text: '#FF6A48',
        success_bg: '#FF8F76',
        success_icon: '#FF8F76',
      },
    },
    divider: '#B3B8B7',
    button: '#F2512C',
  },
  typography: {
    redHatDisplay: {
      regular: 'Red Hat Display',
    },
  },
};

export default defaultTheme;
