import { Button, Fade } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import { BodyMedium, COLORS, Subtitle1 } from "styles";
import { Heading1 } from "styles";
import { ButtonProps, ImageProps } from '../../typings/base';
import breakpoints from "styles/breakpoints";

const Background = styled.section`
  position: relative;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: row;

  ${breakpoints.lg`
    height: 90vh;
  `};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  text-align: center;

  > a {
    width: fit-content;
  }

  ${breakpoints.lg`
    align-items: start;
    text-align: start;
  `}
`;

const FadeContainer = styled(Fade)`
  position: absolute;
  border-top: 7px solid ${COLORS.red};
  border-left: 7px solid ${COLORS.red};
  border-bottom: 7px solid ${COLORS.red};
  border-right: 7px solid ${COLORS.red};
  border-radius: 10px;
  align-self: center;
  max-width: inherit;

  ${breakpoints.lg`
    border-right: unset;
    border-radius: unset;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 200px 24px 0px 24px;

  ${breakpoints.md`
    gap: 100px;
  `}

  ${breakpoints.lg`
    flex-direction: row;
    padding: 150px 0px 100px 70px;
    align-items: center;
  `}
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 350px;
  width: -webkit-fill-available;
  width: -moz-available;

  ${breakpoints.sm`
    height: 550px;
  `}

  ${breakpoints.lg`
    height: unset;
    max-width: 850px;
  `}
`;

type HeroSectionProps = {
  title: string,
  subtitle: string,
  button: ButtonProps,
  images: ImageProps[],
}

export const HeroSection = (props: HeroSectionProps): JSX.Element => {
  const { title, subtitle, button, images } = props;
  const [activeImageIndex, setActiveImageIndex] = React.useState<number>(0);
  const isMobileDevice = window.innerWidth < 992;

  const MINUTE_MS = 4000;

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (activeImageIndex + 1 === images.length) {
        setActiveImageIndex(0);
      } else {
        setActiveImageIndex(activeImageIndex + 1);
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [activeImageIndex, images.length]);

  return (
    <Background id="hero">
      <ContentWrapper>
        <TextWrapper>
          <Heading1>{title}</Heading1>
          <Subtitle1>{subtitle}</Subtitle1>
          <a href={button.href}>
            <Button maxWidth={'fit-content'} backgroundColor={COLORS.red} color={'white'} padding={'30px 70px'} marginTop={'50px'} fontSize={"20px"}>
              {button.label}
            </Button>
          </a>
          {isMobileDevice && (
            <BodyMedium>Beachte, dass das Spiel nur mit Maus und Tastatur spielbar ist!</BodyMedium>
          )}
        </TextWrapper>
        <ImageWrapper>
          {images.map((image, index) => (
            <FadeContainer transition={{enter: {duration: 0.8}, exit: {duration: 0.8}}} key={index} in={activeImageIndex === index}>
              <img src={image.src} alt={image.alt} />
            </FadeContainer>
          ))}
        </ImageWrapper>
      </ContentWrapper>
    </Background>
  )};

  export { HeroSectionProps };
