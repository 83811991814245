//@ts-expect-error
import passwordIcon from '../assets/passwort.png';
//@ts-expect-error
import phishingIcon from '../assets/phishing.png';
//@ts-expect-error
import thirdQuizzIcon from '../assets/third-quizz.png';
//@ts-expect-error
import usbIcon from '../assets/usb.png';
// @ts-expect-error
import HeroSectionImage1 from '../assets/hero-section-1.png';
// @ts-expect-error
import HeroSectionImage2 from '../assets/hero-section-2.png';
// @ts-expect-error
import HeroSectionImage3 from '../assets/hero-section-3.png';
//@ts-expect-error
import TutorialImage from '../assets/tutorial.png';
//@ts-expect-error
import MultiplayerImage from '../assets/play-together.png';
//@ts-expect-error
import CourseImage from '../assets/course.png';

import { SectionProps, SectionType } from 'typings/base';

const sections: SectionProps = [
  { type: SectionType.HERO, data: {
      title: "Escape Room",
      subtitle: "Security Awareness Training",
      button: { label: "Jetzt Spielen", href: "https://security-awareness.tha.de/game" },
      images: [{ src: HeroSectionImage1, alt: "Tisch mit Desktop und verschiedenen Gegenständen" }, { src: HeroSectionImage2, alt: "Tisch mit Desktop und verschiedenen Gegenständen" }, { src: HeroSectionImage3, alt: "Tisch mit Desktop und verschiedenen Gegenständen" } ],
  }},
  { type: SectionType.INTRO, data: {
    title: "Bedienungsanleitung",
    description: `Ähnlich wie bei anderen Spielen können die Charaktere mit den Tasten "W", "A", "S" und "D" in die jeweilige Richtung gelenkt werden. Mithilfe der Maus schwenkt man die Kamera und somit auch das kleine Fadenkreuz in der Mitte des Bildschirms.\n\nZeigt man mit dem Fadenkreuz auf einen Gegenstand und drückt "E", werden dadurch verschiedene Interaktionen ausgeführt, wie beispielsweise das Blättern durch Seiten eines Buches oder das genauere Untersuchen von Gegenständen.\n\nZudem hat man die Möglichkeit mit "M" das Menü und mit "C" den Chat aufzurufen.\n\n\nBitte beachte auch, dass du für das Spiel eine Maus und eine Tastatur, sowie eine stabile Internetverbindung benötigst.`,
  }},
  { type: SectionType.INTRO, data: {
    smallTitle: "Powered by Technische Hochschule Augsburg",
    title: "Security Awareness Training Escape Room",
    description: "Willkommen zu unserem browserbasierten Escape-Room-Spiel in 3D-Grafik!\nBist du bereit, dich dem Sicherheitsdilemma zu stellen und die ultimative Herausforderung des Security Awareness Trainings anzunehmen? \n\nIn unserem herausfordernden Escape-Room mit dem Thema 'Security Awareness Training' wirst du vor eine spannende Mission gestellt. Jeder Schritt und jede Entscheidung bringen dich näher an die Lösung und den Ausgang des Raums. Du wirst verschiedene Aspekte der Sicherheit und des Sicherheitsbewusstseins kennenlernen, während du Rätsel löst, Codes knackst und vor allem deine Intuition und Kreativität einsetzt.",
    button: {label: "Mehr Erfahren", href: "#topic"}
  }},
  { type: SectionType.TOPIC, data: {
    title: "Themen",
    topicCards: [
      {
        icon: passwordIcon,
        title: "Sichere Passwortnutzung",
        description: "Während du die Rätsel unseres Escape-Rooms löst, wirst du lernen, wie man bekannte Pitfalls vermeidet, um die Sicherheit deiner Konten zu gewährleisten. Werde zum Meister der sicheren Passwortnutzung und schütze deine virtuellen Welten vor unerwünschten Eindringlingen!",
        modalText: "Die sichere Passwortnutzung ist von entscheidender Bedeutung, um persönliche Daten und Online-Konten vor unautorisierten Zugriffen zu schützen.\n\nDas Verwenden von leicht zu erratenden Passwörtern macht es für potenzielle Angreifer einfacher, Zugang zu deinen Konten zu erhalten. Die Verwendung allgemeiner Wörter, persönlicher Informationen oder einfacher Zahlenfolgen erleichtert es Angreifern, dein Passwort zu knacken. Ebenso problematisch ist das Aufschreiben von Passwörtern auf einem physischen Notizzettel, der leicht verloren gehen oder von anderen Personen eingesehen werden kann.\n\nStattdessen solltest du komplexe Passwörter wählen, die eine Mischung aus Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen enthalten. Vermeide es, persönliche Informationen wie Namen, Geburtsdaten oder einfache Wörter zu verwenden. Idealerweise sollten Passwörter mindestens 12 Zeichen lang sein, um die Sicherheit zu erhöhen.\n\nEs ist auch ratsam, für verschiedene Konten unterschiedliche Passwörter zu verwenden. Falls ein Passwort kompromittiert wird, bleiben die anderen Konten weiterhin geschützt."
      },
      {
        icon: phishingIcon,
        title: "Phishing",
        description: "Begib dich auf eine spannende Mission, um die Geheimnisse des Phishing-Angriffs zu enthüllen. Während du die Rätsel unseres Escape-Rooms knackst, wirst du die Fähigkeit entwickeln, verdächtige E-Mails zu erkennen, persönliche Informationen zu schützen und dich gegen die Tricks von Cyberkriminellen zu verteidigen.",
        modalText: "Phishing stellt eine ernsthafte Bedrohung für die Sicherheit im digitalen Raum dar. Betrügerische Akteure nutzen raffinierte Taktiken, um ahnungslose Nutzer zur Preisgabe sensibler Informationen zu verleiten.\n\nEin häufiger Fehler, dem viele zum Opfer fallen, besteht darin, klickbare Links in verdächtigen E-Mails anzuklicken und dort persönliche Informationen einzugeben. Diese Links können zu täuschend echten, aber gefälschten Websites führen, die dazu dienen, Anmeldedaten oder sogar Finanzdaten abzugreifen. Es ist entscheidend, niemals sensible Informationen über E-Mail-Anfragen weiterzugeben und stattdessen direkt die offizielle Website des Unternehmens zu besuchen, um sicherzustellen, dass die Anfrage legitim ist.\n\nEine weitere bekannte Phishing-Methode ist das Versenden von E-Mails, die dazu auffordern, Anhänge oder Links zu öffnen, die scheinbar wichtige Informationen enthalten. Diese Anhänge könnten jedoch schädliche Software enthalten, die auf dem Computer des Nutzers installiert wird, um Daten zu stehlen oder das System zu beeinträchtigen.\n\nUm sich vor Phishing zu schützen, ist es entscheidend, verdächtige E-Mails zu identifizieren und nicht auf unerwartete Anfragen zu reagieren. Überprüfe die Echtheit von E-Mails durch direkten Kontakt mit der angeblichen Absenderorganisation und nutze Sicherheitssoftware, um Phishing-Versuche zu erkennen."
      },
      {
        icon: thirdQuizzIcon,
        title: "Social Engineering",
        description: "Tauche ein in die Welt des Social Engineering und meistere die Kunst der Achtsamkeit. Löse die Rätsel unseres Escape-Rooms, um die listigen Tricks der Manipulation zu durchschauen. Entwickle die Fähigkeit, verdächtige Interaktionen zu erkennen und schütze dich vor den täuschenden Strategien sozialer Ingenieure. Deine Sicherheit liegt in deinem Wissen!",
        modalText: `Social Engineering ist eine perfide Methode, bei der Angreifer menschliche Schwächen ausnutzen, um an sensible Informationen zu gelangen oder manipulative Handlungen durchzuführen. Häufig geschieht dies durch Täuschung und Manipulation, indem die Angreifer Vertrauen aufbauen und Opfer dazu bringen, Informationen preiszugeben oder bestimmte Handlungen auszuführen.\n\nEin häufiges Szenario des Social Engineering ist das sogenannte "Phishing am Telefon", bei dem sich Betrüger als vertrauenswürdige Personen ausgeben, wie beispielsweise Mitarbeiter von Banken oder technischer Support. Durch geschicktes Imitieren von Autorität und Überzeugungskraft versuchen sie, ihre Opfer zur Offenlegung von Passwörtern, PINs oder anderen sensitiven Daten zu bewegen.\n\nEine weitere verbreitete Methode des Social Engineering ist das "Pretexting", bei dem die Angreifer eine falsche Identität oder einen Vorwand verwenden, um Zugang zu Informationen zu erhalten. Dies könnte beispielsweise das Vortäuschen einer falschen Autorität oder das Vorspielen eines dringenden Problems sein, um das Opfer dazu zu bringen, vertrauliche Daten preiszugeben.\n\nUm sich vor Social Engineering zu schützen, ist es wichtig, skeptisch gegenüber unerwarteten Anfragen nach sensiblen Informationen zu sein, selbst wenn sie von vertrauenswürdig erscheinenden Quellen kommen. Die Überprüfung der Identität von Anrufern oder E-Mail-Absendern sowie die Schulung von Mitarbeitern in Bezug auf Social Engineering-Taktiken sind entscheidende Schritte zur Prävention.`
      },
      {
        icon: usbIcon,
        title: "Verwendung von USB-Sticks",
        description: "Betrete die Welt der digitalen Datenspeicherung und entdecke die Kunst des sicheren Umgangs mit USB-Sticks. Während du die Rätsel unseres Escape-Rooms löst, wirst du wichtige Techniken erlernen, wie du verantwortungsvoll mit USB-Sticks und mit sensiblen Informationen umgehst.",
        modalText: "Bad USB stellt eine ausgeklügelte Bedrohung für die Sicherheit von Computersystemen dar. Bei diesem Angriff wird ein manipuliertes USB-Gerät verwendet, um Schadsoftware auf das Zielsystem zu übertragen. Was den Bad-USB-Angriff besonders gefährlich macht, ist die Tatsache, dass er auf dem ersten Blick oft nicht erkennbar ist.\n\nDas manipulierte USB-Gerät kann als scheinbar harmloser Speicherstick getarnt sein. Sobald es jedoch mit einem Computer verbunden wird, wird heimlich Schadsoftware auf das System geladen. Dies kann zu erheblichen Sicherheitsrisiken führen, da die Schadsoftware unbemerkt sensible Daten stehlen, das System übernehmen oder sogar weitere Malware nachladen kann.\n\nUm sich vor Bad USB-Angriffen zu schützen, ist es ratsam, USB-Geräte nur von vertrauenswürdigen Quellen zu beziehen und unbekannte Geräte zu vermeiden. Unternehmen sollten zudem sicherstellen, dass ihre Mitarbeiter über die Risiken von manipulierten USB-Geräten informiert sind und entsprechende Sicherheitsrichtlinien befolgen."
      }
    ]
  }},
  { type: SectionType.FEATURE, data: {
    setImageLeft: true,
    image: { src: TutorialImage, alt: "Text mit Einführung zum Spiel" },
    title: "Missionsziel",
    description: "Bevor der Escape Room gestartet wird, durchlaufen die Spieler eine kurze Einführung, welches das Ziel der Mission erklärt und den Spielern eine Starthilfe bietet.\nDie Steuerung wird ebenfalls erklärt, bevor es losgehen kann.\n\nWährend des Spielverlaufs können Hinweise eingelöst werden, falls die Rätsel zu anspruchsvoll sein sollten."
  }},
  { type: SectionType.FEATURE, data: {
    image: { src: MultiplayerImage, alt: "Escape Room mit 3 weiteren Spielern" },
    title: "Zusammen spielen",
    description: "Tauche ein in ein gemeinsames Spielerlebnis und erfülle die Mission im Multiplayer-Modus! Benutzt die Chat-Funktion, um eure Ideen und Funde in Echtzeit auszutauschen."
  }},
  { type: SectionType.FEATURE, data: {
    setImageLeft: true,
    image: { src: CourseImage, alt: "Wettkampf UI" },
    title: "Wettkampf",
    description: "Erstelle deinen eigenen Kurs und lade ausgewählte Spieler ein, an diesem spannenden Abenteuer teilzunehmen. Bildet Gruppen, arbeitet gemeinsam an den Herausforderungen und messt euch in einer Rangliste, um zu sehen, wer die besten Ergebnisse erzielt. Schaffe ein einzigartiges Lernerlebnis, bei dem Teamarbeit und Wettbewerb aufeinandertreffen, und steigere dadurch den Spaß und die Motivation beim Spielen unseres Escape-Rooms!"
  }}
];

export default sections;
