import React from 'react';
import { SectionProps, SectionType } from "typings/base"

import { HeroSection } from './sections/hero-section';
import { IntroSection } from './sections/intro-section';
import { TopicSection } from './sections/topic-section';
import { FeatureSection } from './sections/feature-section';
import { CourseSection } from './sections/course-section';

type Props = {
  sections: SectionProps,
};

const SectionRenderer = ({sections}: Props) => (
  <>
    {sections.map(({ type, data }) => (
      <>
        {type === SectionType.HERO && (<HeroSection {...data} />)}
        {type === SectionType.INTRO && (<IntroSection {...data} />)}
        {type === SectionType.TOPIC && (<TopicSection {...data} />)}
        {type === SectionType.FEATURE && (<FeatureSection {...data} />)}
        {type === SectionType.COURSE && (<CourseSection {...data} />)}
      </>
    ))}
  </>
);

export default SectionRenderer;
