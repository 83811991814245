import React from "react";
import styled from "styled-components";
import { BodyLarge, COLORS, Heading2 } from "styles";
import breakpoints from "styles/breakpoints";
import { ImageProps } from "typings/base";

const Background = styled.section`
  position: relative;
  background-color: ${COLORS.grey};
  display: flex;
  flex-direction: row;
  padding: 50px 24px;
  justify-content: center;

  ${breakpoints.md`
    padding: 70px;
  `}
`

type CardProps = {
  invert?: boolean,
}

const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  width: 100%;
  justify-content: space-between;
  gap: 30px;
  max-width: 1500px;

  ${breakpoints.lg`
    height: 500px;
    flex-direction: ${(props: CardProps) => props.invert ? 'row-reverse' : 'row'};
    gap: 60px;
  `}
`;

const TextWrapper = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 20px 20px;

  ${breakpoints.lg`
    padding: 40px 40px 20px ${(props: CardProps) => props.invert ? '20px' : '40px'};
  `}

  ${breakpoints.xl`
    padding: 40px 40px 20px ${(props: CardProps) => props.invert ? '20px' : '100px'};
  `}
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  max-height: 500px;

  ${breakpoints.lg`
    max-width: 550px;
    min-width: 430px;
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

type FeatureSectionProps = {
  image: ImageProps,
  title: string,
  description: string,
  setImageLeft?: boolean,
}

export const FeatureSection = (props: FeatureSectionProps): JSX.Element => {
const {image, title, description, setImageLeft = false} = props;
return (
  <Background id={`feature-card-${title}`}>
    <Card invert={setImageLeft}>
      <TextWrapper invert={setImageLeft}>
        <Heading2 color={COLORS.orange}>{title}</Heading2>
        <BodyLarge>{description}</BodyLarge>
      </TextWrapper>
      <ImageWrapper>
        <Image src={image.src} alt={image.alt}/>
      </ImageWrapper>
    </Card>
  </Background>
)};

export {
  FeatureSectionProps
}
