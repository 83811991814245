import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Homepage from './pages/homepage';

const isDevelopment = process.env.NODE_ENV === 'development';

const basename = isDevelopment ? '/' : '/';

const App = (): JSX.Element => (
  <BrowserRouter basename={basename}>
    <Routes>
      <Route path='/' element={<Homepage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
