import React from "react";
import styled from "styled-components";
import { COLORS, Heading3, } from "styles";
import { TopicCard } from "../topic-card";
import { CustomModal } from "../modal";
import breakpoints from "styles/breakpoints";

const Background = styled.section`
  position: relative;
  background-color: ${COLORS.grey};
  display: flex;
  flex-direction: row;
  place-content: center;
  padding: 70px 0px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: ${COLORS.white};
  align-items: center;
  padding: 100px 24px;

  ${breakpoints.lg`
    padding: 100px 50px;
  `}
`;

const TopicWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 150px;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 800px;

  ${breakpoints.md`
    gap: 80px;
  `}

  ${breakpoints.xl`
    max-width: unset;
    gap: 50px;
  `}
`;

type TopicSectionProps = {
  title: string,
  topicCards: {
    icon: string,
    title: string,
    description: string,
    modalText: string,
  }[],
}

export const TopicSection = (props: TopicSectionProps): JSX.Element => {
  const { title, topicCards } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [modalText, setModalText] = React.useState<string>('');
  const [modalTitle, setModalTitle] = React.useState<string>('');

  return (
    <Background id="topic">
      <CustomModal isOpen={openModal} onClose={() => setOpenModal(false)} text={modalText} title={modalTitle} />
      <Box>
        <Heading3 color={COLORS.orange}>{title}</Heading3>
        <TopicWrapper>
          {topicCards.map((card): JSX.Element => {
            return (
              <TopicCard
                icon={card.icon}
                title={card.title}
                description={card.description}
                onClickMore={(title: string) => {setOpenModal(true); setModalText(card.modalText); setModalTitle(title)}}
              />
            )
          })}
        </TopicWrapper>
      </Box>
    </Background>
  );
}

export { TopicSectionProps };
