import { Button } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import { BodyLarge, COLORS, Heading3, Subtitle1 } from "styles";
import breakpoints from "styles/breakpoints";
import { ButtonProps } from "typings/base";

const Background = styled.section`
  position: relative;
  background-color: ${COLORS.white};
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;

  ${breakpoints.lg`
    min-height: 1vh;
  `}
`

const TextWrapper = styled.div`
  display: flex;
  place-items: center;
  flex-direction: column;
  gap: 25px;
  width: 100%;

  max-width: 950px;
  padding: 100px 24px;

  ${breakpoints.md`
    padding: 100px;
  `}

  ${breakpoints.lg`
    padding: 1px 100px;
  `}
`;

type IntroSectionProps = {
  smallTitle?: string,
  title: string,
  description: string,
  button?: ButtonProps,
}

export const IntroSection = (props: IntroSectionProps): JSX.Element => {
  const { smallTitle, title, description, button } = props;

  return (
    <Background id="intro">
        <TextWrapper>
          <Subtitle1 textAlignCenter>{smallTitle}</Subtitle1>
          <Heading3 textAlignCenter color={COLORS.orange}>{title}</Heading3>
          <BodyLarge textAlignCenter>{description}</BodyLarge>
          {button && (
            <a href={button.href}>
            <Button maxWidth={'fit-content'} backgroundColor={COLORS.red} color={'white'} padding={'25px 25px'} borderRadius={'10px'} marginTop={'15px'} fontSize={'18px'}>{button.label}</Button>
            </a>
          )}
        </TextWrapper>
    </Background>
  )};

  export { IntroSectionProps };
