import React from "react";
import styled from "styled-components";
import { BodyMedium, BodyMediumLink, COLORS } from "styles";

const Background = styled.section`
  position: relative;
  background-color: ${COLORS.grey};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const Footer = (): JSX.Element => (
  <Background id={"footer"}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px'}}>
        <BodyMediumLink href="https://www.hs-augsburg.de/Service/Impressum.html">Impressum</BodyMediumLink>
        <BodyMediumLink href="https://www.hs-augsburg.de/Service/Datenschutz.html">Datenschutz</BodyMediumLink>
      </div>
      <BodyMedium textAlignCenter>{`© ${new Date().getFullYear()} Technische Hochschule Augsburg`}</BodyMedium>
  </Background>
);
