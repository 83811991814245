import React from "react";
import styled from "styled-components";
import { BodyLarge, COLORS, Heading2 } from "styles";
import { ImageProps } from "typings/base";

const Background = styled.section`
  position: relative;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: row;
  min-height: 500px;
  width: 100%;
`;

const TextWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding-left: 230px;
  justify-content: center;
  gap: 20px;
`;

const ImageWrapper = styled.div`
  width: auto;
  min-width: 20%;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
`;

type CourseSectionProps = {
  image: ImageProps,
  title: string,
  description: string,
}

export const CourseSection = (props: CourseSectionProps): JSX.Element => {
  const { image, title, description } = props;

  return (
    <Background id={"course-section"}>
        <ImageWrapper>
          <Image src={image.src} alt={image.alt}/>
        </ImageWrapper>
        <TextWrapper>
          <Heading2 color={COLORS.orange} width="600px">{title}</Heading2>
          <BodyLarge color={COLORS.black} width="600px">{description}</BodyLarge>
        </TextWrapper>
    </Background>
  )
};

export {
  CourseSectionProps
}
