import { CourseSectionProps } from "components/sections/course-section";
import { FeatureSectionProps } from "components/sections/feature-section";
import { HeroSectionProps } from "components/sections/hero-section";
import { IntroSectionProps } from "components/sections/intro-section";
import { TopicSectionProps } from "components/sections/topic-section";

type ImageProps = {
  src: string,
  alt: string,
}

type ButtonProps = {
  label: string,
  href: string,
}

enum SectionType {
  HERO = "hero-section",
  INTRO = "intro-section",
  TOPIC = "topic-section",
  FEATURE = "feature-section",
  COURSE = "course-section",
}

type SectionProps = (
  { type: SectionType.HERO, data: HeroSectionProps }
  | { type: SectionType.INTRO, data: IntroSectionProps }
  | { type: SectionType.TOPIC, data: TopicSectionProps }
  | { type: SectionType.FEATURE, data: FeatureSectionProps }
  | { type: SectionType.COURSE, data: CourseSectionProps }
)[]

export {
  ImageProps,
  ButtonProps,
  SectionType,
  SectionProps,
}
